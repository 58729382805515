/*import Vue from 'vue'*/
// import Vue from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'



// Vue.use(createRouter)

// 创建路由器对象
const router = createRouter({
    history: createWebHashHistory(),
    // 定义当前路由器对象管理的路由信息
    routes: [
        // 每一条路由信息，就是一个对象
        {
            // 路由路径
            path:'/',
            // 路由名称
            name:'home',
            // 路由组件
            component: () => import('@/views/homePage/index'),
            meta: { title: '首页' }
        },
        {
            path:'/aggregate',
            // 路由名称
            name:'MainPart',
            // 路由组件
            component: () => import('@/views/homePage/aggregate'),
            meta: { title: '产品与解决方案' }
        },
        {
            path:'/aboutCompany',
            // 路由名称
            name:'AboutCompany',
            // 路由组件
            component: () => import('@/views/aboutCompany/companyContent'),
            meta: { title: '关于公司' }
        },
        /*{
            path:'/tiaoShi',
            // 路由名称
            name:'TiaoShi',
            // 路由组件
            component: () => import('@/views/menuPage/jiQiRen/bankJiQiRen'),
            meta: { title: '调试页面' }
        },*/
        /*{
            path:'/pingTai',
            // 路由名称
            name:'PingTai',
            // 路由组件
            component: () => import('@/views/homePage/pingTaiJianShe'),
            meta: { title: '平台建设' }
        },*/
        {
            path:'/test',
            // 路由名称
            name:'Test',
            // 路由组件
            component: () => import('@/views/homePage/test'),
            meta: { title: '测试' }
        }
        /*{
            path: '/list',
            name: 'list',
            component: List
        }*/
    ]
})

export default router
