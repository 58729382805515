<template>
    <div class="top-navigation">
        <div class="top-wrap">
            <div class="top-left">
                <img class="img-width-100"  src="../../public/img/shouye/yihu-baiying-logo.png"  @click="returnHome()">
            </div>
            <div class="top-right">
                <div class="menu-one menu-one-active">首页</div>
                <div class="menu-one">公司介绍</div>
                <div class="menu-one">公司业务</div>
                <div class="menu-one">新闻中心</div>
                <div class="menu-one">人力资源</div>
                <div class="menu-one">联系我们</div>
            </div>
        </div>
    </div>
</template>

<script>
/*import Swiper from 'swiper'
import 'swiper/css/swiper.css'*/
export default {
    name: "TopNavigation",
    /*components: {
        Swiper
    },*/
    data() {
        return {
            account: undefined,
            password: undefined,
            swiper: undefined,
            chooseTitleIndex: 0,
            topTitleList: ['首页', '平台建设', '全流程咨询', '跨界运营', '整合营销', '招商与客户营销', '代运营', '仓储/物流', '增值服务', '收费资讯']
        }
    },
    props:{
        choosseIndex: { type: Number, default: 0 },
    },
    created() {
        console.log(this.choosseIndex)
    },
    methods: {
        chooseOneTitle(index) {
            this.chooseTitleIndex = index
            if (index == 0) {
                this.$router.push('/')
            } else {
                /*this.$router.push({path: '/pingTai', query: {titleIndex: index}})*/
            }
        },
        // 返回首页
        returnHome() {
            this.$router.replace('/')
        }
    }
}
</script>

<style scoped lang="scss">
   .img-width-100{
       width: 100%;
   }
    .top-wrap{
        background: #1f54f8;
        padding: 22px 77px 6px 5vw;
        display: flex;
        justify-content: space-between;
        .top-left{
            width:11vw;
        }
        .top-right{
            display: flex;
            align-items: center;
            color: #393939;
            font-size: 16px;
            .menu-one{
                /*border: 1px solid red;*/
                width: 9vw;
                font-size: 18px;
                color: #ffffff;
                text-align: center;
                padding: 4px 0px;
            }
            .menu-one-active{
                background: #0063f6;
                border-radius: 4px;
            }
        }
    }
   a {
       text-decoration: none; /*去除下划线*/
       color: inherit; /*继承父元素颜色*/
   }
</style>
