<template>
  <div>
    <!--<img alt="Vue logo" src="./assets/logo.png">-->
    <TopNavigation v-if="path == '/' || path== '/aggregate' || path== '/aboutCompany'" msg="顶部菜单栏" />
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <router-view></router-view>
    <BottomNavigation v-if="path == '/' || path== '/aggregate' || path== '/aboutCompany'" msg="低部菜单栏"/>
  </div>

</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import TopNavigation from './components/TopNavigation.vue'
import BottomNavigation from './components/BottomNavigation.vue'

export default {
  name: 'App',
  components: {
    TopNavigation,
    BottomNavigation
  },
  data() {
    return{
      chooseTopIndex: 0,
      path: '', // 当前路径
    }
  },
  mounted() {
    // 当前页面路径
    this.path = this.$route.path
    /*console.log(8888)
    console.log(this.path)
    console.log(this.$route.path)*/
  },
  watch: {
    $route(to) {
      // 打开新页面的路由
      this.path = to.path
    }
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;*/
  /*margin-top: 60px;*/
  margin: 0px;
}
</style>
