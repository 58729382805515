/*import { createApp } from 'vue'*/
import App from './App.vue'
import  { createApp } from 'vue'
// 导入当前项目中创建的路由器对象
import router from './router'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// import 'swiper/swiper-bundle.css'
/*createApp(App).mount('#app')*/

const app = createApp(App)
app.use(router)
app.mount('#app')
router.beforeEach((to, from, next) => {
    // 页面跳转让页面回到顶部
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
    next()
})
// app.use(VueAwesomeSwiper)
/*new Vue({
    el: '#app',
    router,
    render: h => h(App)
})*/
/*new Vue({
    render: h => h(App),
    // 在Vue的实例上，配置一个路由器对象
    router:router
}).$mount('#app')*/
