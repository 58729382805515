<template>
    <div class="BottomNavigation">
        <div class="info-list-wrap">
            <dl style="width: 20%">
                <dt>公司简介</dt>
                <dd><router-link :to="{path:'aboutCompany', query: {index: 1}}">公司概况</router-link></dd>
                <dd><router-link :to="{path:'aboutCompany', query: {index: 2}}">高管介绍</router-link></dd>
            </dl>
            <dl style="width: 20%">
                <dt>公司业务</dt>
                <dd><router-link to="/">首页</router-link></dd>
                <dd><router-link to="/">百应数据</router-link></dd>
                <dd><router-link to="/">百应科技</router-link></dd>
                <dd><router-link to="/">百应咨询</router-link></dd>
            </dl>
            <dl style="width: 20%">
                <dt>新闻中心</dt>
                <dd><router-link to="/">公司新闻行业新闻</router-link></dd>
            </dl>
            <dl style="width: 20%">
                <dt>人力资源</dt>
                <dd><router-link to="/">员工风采招聘信息</router-link></dd>
            </dl>
            <dl style="width: 20%">
                <dt>联系我们</dt>
                <dd><router-link to="/">18550959955</router-link></dd>
            </dl>
        </div>
        <div class="bei-an-wrap">
            <div style="margin-right: 30px;">版权所有 © {{nowYear}} 一呼百应科技有限公司 </div>
            <div>电信与信息服务业务许可证号：<a href="https://beian.miit.gov.cn" target = "blank">苏ICP备2024066791号</a></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BottomNavigation",
        data() {
            return{
                nowYear: undefined
            }
        },
        created() {
          this.getNowYear()
        },
        methods: {
            getNowYear() {
                var date = new Date()
                this.nowYear = date.getFullYear()
            }
        }
    }
</script>

<style scoped>
    .BottomNavigation{
        background: #303030;
    }
    .info-list-wrap{
        overflow: hidden;
        /*padding: 1.5vw 7vw;*/
        padding: 1.5vw 3vw 1.5vw 7vw;
    }
    .info-list-wrap dl{
        float: left;
        /*width: 200px;*/
        text-align: left;
        /*border: 1px solid red;*/
    }
    .info-list-wrap dt{
        color: #ffffff;
        font-size: 24px;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
    .info-list-wrap dd{
        color: #ffffff;
        font-size: 15px;
        margin-left: 0px;
        letter-spacing: 1px;
        line-height: 22px;
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;  /*去除默认样式*/
        color: #cbcbcb; /*高亮的颜色*/
    }

    .bei-an-wrap{
        background: #ffffff;
        color: #636363;
        font-size: 13px;
        display: flex;
        justify-content: center;
        padding: 26px;
    }
</style>
